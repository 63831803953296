import { useCallback, useState, useEffect } from 'react'
import { requireAuth } from '../wrappers/requireAuth'
import { useUser } from "../hooks/useUser"
import { useAsyncAction } from '../hooks/useAsyncAction'
import { useClient } from '../providers/supabase'
import { useAppDispatch, useAppSelector } from '../hooks/reduxToolkit'
import { setSettings } from '../reduxToolkit/membershipSlice'
import { merge } from "lodash";
import { useLoadSettingsFromServer } from '../hooks/useLoadSettingsFromServer'
import { SettingsRow, SettingsUpdate } from '../../types/supabase'
import { TextractOptInCheckbox } from '../components/forms/textractOptInCheckbox'
import { Tooltip } from '../components/tooltip'
import { usePushNotifications } from '../providers/pushNotifications'
import { useSentry } from '../providers/sentry'

function EditSettings() {
  const user = useUser()
  if (!user) { throw new Error(`Not signed in!`) }

  const client = useClient()
  const settings = useAppSelector((s) => s.membership?.settings)
  const dispatch = useAppDispatch()

  useLoadSettingsFromServer()

  const [{error, loading}, updateSettings] = useAsyncAction(async (update: SettingsUpdate) => {
    const now = new Date().toISOString()
    const newRow = {
      ...update,
      updated_at: now
    }
    const result = await client.from('settings')
      .update(newRow)
      .eq('user_id', user.id)

    if (result.error) { throw result.error }

    dispatch(setSettings({
      settings: merge({}, settings, newRow)
    }))
  }, [client])

  return <div className='row'>
    <div className='col-12'>
      <h1 className='text-center'>Edit your Settings</h1>
      
      <div className='mt-4'>
        <SettingsForm settings={settings} loading={loading} onUpdate={updateSettings} />
      </div>
      {error && <div className='alert alert-danger' role='alert'>
        {error.message}
      </div>}
    </div>
  </div>
}

export default requireAuth(EditSettings)

interface SettingsFormProps {
  settings: SettingsRow | null,
  loading?: boolean,
  onUpdate: (settings: SettingsUpdate) => void
}

function SettingsForm({settings, loading, onUpdate}: SettingsFormProps) {
  const [unsavedSettings, setUnsavedSettings] = useState<SettingsUpdate>(settings || {})
  
  const update = useCallback((update: SettingsUpdate) => {
    setUnsavedSettings((s) => merge({}, s, update))
    onUpdate(update)
  }, [onUpdate])

  return <form className="settings-form">
    <h4>Notification Settings</h4>
    <div className='form-group'>
      <AllowPushNotificationsCheckbox
        value={unsavedSettings.notification_prefs_push || false}
        disabled={loading && (unsavedSettings.notification_prefs_push !== settings?.notification_prefs_push)}
        onChange={(value) => {
          update({notification_prefs_push: value})
        }}
        />
    </div>

    <hr />
    <h4>Opt-In Settings</h4>
    <div className='form-group'>
      <TextractOptInCheckbox
        value={unsavedSettings.textract_opt_in || null}
        disabled={loading && (unsavedSettings.textract_opt_in !== settings?.textract_opt_in)}
        onChange={(value) => {
          update({textract_opt_in: value})
        }} />
    </div>
  </form>
}

interface AllowPushNotificationsCheckboxProps {
  value: boolean
  disabled?: boolean
  onChange: (value: boolean) => void
}

function AllowPushNotificationsCheckbox({ value, disabled, onChange }: AllowPushNotificationsCheckboxProps) {
  const pushNotifications = usePushNotifications()
  const Sentry = useSentry()
  const [error, setError] = useState<Error | null>(null)
  
  const permissionState = pushNotifications.getPermissionState()
  
  console.log('permissionState', permissionState, pushNotifications.constructor.name)

  const handleChange = async (checked: boolean) => {
    if (checked) {
      try {
        await pushNotifications.register()
        onChange(true)
      } catch (error) {
        console.error('Error requesting notification permission:', error)
        Sentry.captureException(error)
        setError(error as Error)
      }
    } else {
      onChange(false)
    }
  }

  return (
    <div className='form-check form-switch'>
      <Tooltip tooltip={permissionState == 'not-supported' ? 'Push notifications are not supported by your phone or browser' : undefined}>
        <input
          type='checkbox'
          className={`form-check-input ${disabled || permissionState == 'not-supported' ? 'disabled' : ''}`}
          id='notifications-checkbox'
          checked={value}
          onChange={(e) => {
            if (disabled || permissionState == 'not-supported') {
              return
            }
            handleChange(e.target.checked)
          }}
          />
      </Tooltip>
      <label className='form-check-label' htmlFor='notifications-checkbox'>
        Allow Push Notifications
        {permissionState === 'denied' && (
          <small className="text-danger d-block">
            Notifications are blocked. Please enable them in your phone or browser settings.
          </small>
        )}
        {error && (
          <small className="text-danger d-block">
            {error.message}
          </small>
        )}
      </label>
    </div>
  )
}
