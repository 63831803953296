import React, { useEffect, useState } from 'react'

import { createClient, Session } from '@supabase/supabase-js'
import { Database } from '../../types/generated/public'
import { AppSupabaseClient } from '../../types/supabase'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || 'http://localhost:54321'
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY || 'test'

export const supabase: AppSupabaseClient = createClient<Database>(supabaseUrl, supabaseAnonKey)

interface SupabaseContext {
  online: boolean
  supabase: AppSupabaseClient,
  session: Session | null
}

const supabaseContext = React.createContext<SupabaseContext>({
  online: false,
  supabase,
  session: null
})

export function SupabaseProvider({children}: React.PropsWithChildren) {

  return <SupabaseContextProvider client={supabase}>
      {children}
    </SupabaseContextProvider>
}

function SupabaseContextProvider({ children, client }: { children: React.ReactNode, client: AppSupabaseClient }) {
  const [session, setSession] = useState<Session | null>(null)
  const [initialized, setInitialized] = useState(false)
  const [online, setOnline] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    // We ought to get an onAuthStateChange when the session is loaded/refreshed
    // If we don't something went wrong
    const timeout = setTimeout(() => {
      setInitialized(true)
      setError(new Error('Session not loaded'))
    }, 5000)

    const {data: {subscription}} = client.auth.onAuthStateChange((event, session) => {
      console.debug('Auth state changed', event, session)
      
      // Allow access to the `/admin` path if the user is logged in
      if (session) {
        document.cookie = `auth_token=${session.access_token}; max-age=${session.expires_in}; path=/admin; Secure; SameSite=Strict`
      } else {
        document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/admin;'
      }
      
      setSession(session)
      setInitialized(true)
      clearTimeout(timeout)
    })

    return () => {
      subscription.unsubscribe()
      clearTimeout(timeout)
    }
  }, [client])

  if (error) { throw error }

  if (!initialized) {
    return null;
  }

  return <supabaseContext.Provider value={{
        online,
        supabase: client,
        session
      }}>
      {children}
    </supabaseContext.Provider>
}

/**
 * Returns true if the user is connected to the Supabase server
 */
export function useIsOnline() {
  //return React.useContext(supabaseContext).online

  // Disable this for now
  return true
}

export const useClient = () => React.useContext(supabaseContext).supabase

export const useSession = () => React.useContext(supabaseContext).session
