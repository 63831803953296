import React from "react";
import { ReduxToolkitProvider } from "./providers/reduxToolkit";
import { SupabaseProvider } from "./providers/supabase";
import { SentryProvider } from "./providers/sentry";
import { ConfirmDialogProvider } from "./providers/confirmDialog";
import { PushNotificationsProvider } from "./providers/pushNotifications";

export function Providers({children}: React.PropsWithChildren) {
  return <SentryProvider>
      <SupabaseProvider>
      <ReduxToolkitProvider>
      <ConfirmDialogProvider>
      <PushNotificationsProvider>
        {children}
      </PushNotificationsProvider>
      </ConfirmDialogProvider>
      </ReduxToolkitProvider>
    </SupabaseProvider>
  </SentryProvider>
}
