import { useEffect } from "react"

/**
 * On page load, check if there is a returnTo in localStorage and redirect to that page.
 */
export function useReturnTo() {
  useEffect(() => {
    const returnTo = localStorage.getItem('returnTo')
    console.log('returnTo', returnTo)
    if (returnTo) {
      localStorage.removeItem('returnTo')
      localStorage.setItem('returningTo', returnTo)
      window.location.href = returnTo
    }
  }, [])
}
