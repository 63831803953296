import { useEffect, useState } from "react"

export function SplashScreen({timeout}: { timeout?: number }) {
  const [error, setError] = useState<Error | null>(null)
  
  useEffect(() => {
    let timer: NodeJS.Timeout
    if (timeout) {
      timer = setTimeout(() => {
        setError(new Error('SplashScreen Load Timeout'))
      }, timeout)
    }
    
    return () => {
      clearTimeout(timer)
    }
  }, [])
  
  if (error) {
    throw error
  }
  
  return <div className="row h-100">
    <div className="col-12 col-lg-6 offset-lg-3 d-flex flex-column justify-content-center align-items-center w-100">
      <img src={'/icon-512.png'} alt="logo" style={{ width: '48px' }} />

      <div className="loading-bar mt-2" role="status" style={{ width: '48px' }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
}
