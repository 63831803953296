import { useEffect, useRef, useState } from "react"
import { useAsyncAction } from "../hooks/useAsyncAction"


export function Test() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const serviceWorkerRegistration = useRef<ServiceWorkerRegistration | null>(null)
  const [hasPeriodicSyncPermission, setHasPeriodicSyncPermission] = useState(false)
  const [periodicSyncError, setPeriodicSyncError] = useState<Error | null>(null)
  
  useEffect(() => {
    setLoading(true)
    navigator.serviceWorker.ready.then(registration => {
      serviceWorkerRegistration.current = registration
    })
  
    navigator.permissions.query({
      name: 'periodic-background-sync' as PermissionName,
    }).then(status => {
      console.log('periodic-background-sync permission', status)
      setHasPeriodicSyncPermission(status.state === 'granted')
    })
      .catch(error => {
        setPeriodicSyncError(error)
      })
    
    const timeout = setTimeout(() => {
      setLoading(false)
      if (!serviceWorkerRegistration.current) {
        setError(new Error('Service worker registration timed out'))
      }
    }, 10_000)
    
    return () => clearTimeout(timeout)
  }, [])
  
  
  const hasServiceWorker = 'serviceWorker' in navigator
  const hasPeriodicSync = hasServiceWorker && serviceWorkerRegistration.current && 'periodicSync' in serviceWorkerRegistration.current
  
  return <div className="help">
    <div className="row">
      <div className="col">
        <h1>Features</h1>
        
        <ul>
          <li>Service Worker: {hasServiceWorker ? 'Supported' : 'Not Supported'}</li>
          {!loading && hasServiceWorker && 
            <li>Service Worker Registration: {serviceWorkerRegistration.current ? 'Ready' : 'Not Ready'}</li>}
          {!loading && hasServiceWorker && serviceWorkerRegistration.current &&
            <li>Periodic Sync: {hasPeriodicSync ? 'Supported' : 'Not Supported'}</li>}
          {error && <li>
            Service Worker Registration Error: <br/>
            <code className="text-danger">{error.message}</code><br/>
            <pre>
              {error.stack}
            </pre>
          </li>}
          {!loading && hasServiceWorker && serviceWorkerRegistration.current &&
            <li>Periodic Sync Permission: {hasPeriodicSyncPermission ? 'Granted' : 'Not Granted'}</li>}
          {periodicSyncError && <li>
            Periodic Sync Permission Error: <br/>
            <code className="text-danger">
              {periodicSyncError.message}
            </code><br/>
            <pre>
              {periodicSyncError.stack}
            </pre>
          </li>}
          {loading && <li>
            <div className="spinner-border" role="status">
            </div>
            <span className="ms-2">Loading...</span>
          </li>}
        </ul>

        <hr />
        
        <TestServiceWorkerBackgroundSync disabled={!serviceWorkerRegistration.current} />
      </div>
    </div>
  </div>
}

function TestServiceWorkerBackgroundSync({ disabled }: { disabled?: boolean }) {
  const [messages, setMessages] = useState<string[]>([])
  
  const [{ loading, error }, runTest] = useAsyncAction(async () => {
    const registration = await navigator.serviceWorker.ready
    registration.active!.postMessage({
      type: 'simulate-periodic-sync',
      tag: 'check-notifications'
    })
    setMessages(m => [...m, 'Message sent to service worker'])
  })
  
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      setMessages(m => [...m, `Received response: ${JSON.stringify(event.data)}`])
    }
    navigator.serviceWorker.addEventListener('message', listener)
    
    return () => {
      navigator.serviceWorker.removeEventListener('message', listener)
    }
  }, [])
  
  return <div>
    <button className={`btn btn-info ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      onClick={runTest}>Service Worker Background Sync</button>
    {loading && <div>Loading...</div>}
    {error && <div>Error: {error.message}</div>}
    <ul>
      {messages.map((m, i) => <li key={i}>{m}</li>)}
    </ul>
  </div>
}
