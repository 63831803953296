import React from "react";
import { OnlineStatusListener } from "./listeners/onlineStatus";
import { SyncActorListener } from "./listeners/syncActor";
import { AppBadgeListener } from "./listeners/appBadge";

/**
 * Listeners are global components that listen for certain state changes and
 * perform background actions in response.
 * They are mounted after all initializers have completed.
 */
export function Listeners({children}: React.PropsWithChildren) {

  return <>
    <SyncActorListener />
    <OnlineStatusListener />
    <AppBadgeListener />
    {children}
  </>

}
