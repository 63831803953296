import keyBy from 'lodash/keyBy'
import type { PostgrestError, RealtimeChannel, RealtimePostgresChangesPayload } from "@supabase/supabase-js"
import type { ExpenseModel } from "../../app/reduxToolkit/expensesSlice"
import type { IncidentModel } from "../../app/reduxToolkit/incidentsSlice"
import type { AttachmentModel } from "../../app/reduxToolkit/attachmentsSlice"
import type { SubmissionModel } from "../../app/reduxToolkit/submissionsSlice"
import type { NotificationModel } from "../../app/reduxToolkit/notificationsSlice"
import type { Add } from "./syncAdd"
import type { RootState, AppDispatch } from "../../app/reduxToolkit/store"
import { AppSupabaseClient, Data, isDeleted, isNotDeleted } from "../../types/supabase"
import { onSyncUpComplete } from "../../app/reduxToolkit/syncSlice"
import { SyncResult, syncTableActions } from "./syncTableActions"
import { SyncableTable, SyncableTables, raiseUnknownSyncableTable } from "./action"
import { OnSyncDownCompletePayload, onSyncDownComplete } from "../../app/reduxToolkit/actions/onSyncDownComplete"
import { convertServerTimestampsToISO } from "../supabase/util"
import { AdvanceRequestModel, AdvanceModel } from "../../app/reduxToolkit/advancesSlice"
import { EventEmitter } from '../util/eventEmitter'

/**
 * This class is an Actor that runs in the background to sync the pending
 * actions with the server.  It manages exponential backoff and retries.
 */
export class SyncActor extends EventEmitter {
  private readonly _interval = 1000
  private _lastSucceededAt?: number
  private _nextSync?: ReturnType<typeof setTimeout>
  private _channel: RealtimeChannel | undefined

  public get lastSucceededAt() {
    return this._lastSucceededAt
  }

  constructor(
    private readonly _supabase: AppSupabaseClient,
    private readonly _store: { getState: () => RootState },
    private readonly _dispatch: AppDispatch,
    private readonly _tables: Readonly<SyncableTable[]> = SyncableTables,
    private readonly _syncTableActions: typeof syncTableActions = syncTableActions
  ) {
    super()
  }

  public start() {
    const membershipId = this._store.getState()?.membership?.membershipId
    if (!membershipId) {
      this.emit('info', 'no membershipId, not starting')
      return
    }

    let channel = this._supabase.channel('sync')
    channel = this._tables.reduce((ch, table) => {
      return channel.on('postgres_changes',
      {
        schema: 'public',
        table: table,
        event: '*',
        filter: `membership_id=eq.${membershipId}`
      } , (payload) => {
        this.onRealtimeUpdate(table, payload as RealtimePostgresChangesPayload<Data<typeof table>>)
          .catch((error) => this.emit('error', error))
      })
    }, channel)

    this._channel = channel.subscribe((status, err) => {
      this.emit('info', `channel status: ${status}`)
      switch(status) {
        case 'SUBSCRIBED':
          // On connect, start sync
          clearTimeout(this._nextSync)
          this._nextSync = setTimeout(() => this._syncUpWorker(), this._interval)
          this.syncDown()
            .catch((error) => this.emit('error', error))
          break
        default:
          // Not connected - stop sync
          clearTimeout(this._nextSync)
          this._nextSync = undefined
          if (err) { this.emit('error', err) }
      }
    })
  }

  public stop() {
    if (this._nextSync) {
      clearTimeout(this._nextSync)
    }

    this._channel?.unsubscribe()
      .catch((error) => {
        this.emit('error', error)
      })
  }

  private async _syncUpWorker(retries: number = 0) {
    try {
      await this.syncUp()
      this._nextSync = setTimeout(() => this._syncUpWorker(), this._interval)
    } catch (error: any) {

      this.emit('error', error)

      // backoff
      retries++
      const delay = Math.min(2 ** retries * 1000, 60000)
      this._nextSync = setTimeout(() => this._syncUpWorker(retries), delay)
    }
  }

  /**
   * This method periodically checks if we need to sync anything up to the server.  It looks at the pending actions
   * in the redux syncSlice and calls syncTableActions to sync them up.  If there are any errors, it will retry
   * with exponential backoff.
   */
  public async syncUp() {
    let result: SyncResult | undefined = undefined

    const state = this._store.getState()

    const pending = state.sync?.pending || []
    if (pending.length > 0) {
      result = await this._syncTableActions(
        this._supabase,
        pending,
      )
      this.emit('info', `sync result: applied ${result.applied.length}, rejected ${result.rejected.length}, error ${result.error.length}`)
      this._dispatch(onSyncUpComplete(result))

      if (result.error.length > 0) {
        // throw an error to get into our exponential backoff
        const errorMsg = result.error.map((e) => `${e.table} ${e.type} ${e.record?.id}: ${e.error?.message}`).join(`\n  `)
        throw new SyncError(`Some errors occurred during sync:\n  ${errorMsg}`, result)
      }
    }

    this._lastSucceededAt = Date.now()
    return result
  }

  /**
   * This method does a full two-way sync with the server.  It queries the server for the ID and updated at of all
   * records in the database, then compares that with the local state.
   * If any records are updated on the server and not locally, it dispatches the onLoadStateFromServer action to update
   * the local redux store.
   */
  public async syncDown() {
    const state = this._store.getState()
    const errors: PostgrestError[] = []

    const payload: OnSyncDownCompletePayload = {

    }

    for (const table of this._tables) {
      const result = await this._supabase.from(table)
        .select('id, updated_at, deleted_at')
        .eq('membership_id', state.membership.membershipId)

      if (result.error) {
        errors.push(result.error)
        continue
      }

      // Ensure all the timestamps in the records are in ISO format for easy comparison
      const data = result.data.map(convertServerTimestampsToISO)

      const tablePayload: OnSyncDownCompletePayload[SyncableTable] = payload[table] = {}

      const deletedLocally = state.sync.deletedLocally?.[table]
      const localRecords = {
        ...keyBy(selectRecords(state, table), 'id'),
        ...keyBy(deletedLocally || [], 'id')
      }

      // all the records on the server that are newer than the ones we have locally
      const newerRecordsOnServer = data
        .filter((r) => {
          if (localRecords[r.id]) {
            // We have a matching local record - is the server record newer?
            return r.updated_at > localRecords[r.id].updated_at
          } else {
            // The server record does not exist locally, whether deleted or not.
            return true
          }
        })

      const deletedOnServer = newerRecordsOnServer
        .filter(isDeleted)
      if (deletedOnServer.length > 0) {
        // No need to get the full record from the server, just mark it as deleted
        tablePayload.deleted = deletedOnServer
      }

      const updatedOnServer = newerRecordsOnServer
        .filter(isNotDeleted)
        .map((r) => r.id)

      // Need to get the full record from the server
      if (updatedOnServer.length > 0) {
        const updatedResult = await this._supabase.from(table)
          .select('*')
          .in('id', updatedOnServer)

        if (updatedResult.error) {
          errors.push(updatedResult.error)
          continue
        }

        // The updatedResult from the server is for this table, but Typescript can't infer that the particular table
        // in tablePayload is the same one in the updatedResult.data
        tablePayload.updated = updatedResult.data.map(convertServerTimestampsToISO) as any
      }

      const newerLocalRecords = Object.values(localRecords)
        .filter((r) => {
          const serverRecord = data.find((s) => s.id === r.id)
          if (serverRecord) {
            // We have a matching server record - is the local record newer?
            return r.updated_at > serverRecord.updated_at
          } else {
            // The local record does not exist on the server, whether deleted or not.
            return true
          }
        })

      newerLocalRecords.forEach((r) => {
        // Do we have a pending syncAction for this record?
        const pending = state.sync?.pending?.find((p) =>
          p.table === table &&
          p.record.id === r.id &&
          p.record.updated_at >= r.updated_at)
        if (pending) { return }

        // Nope - we need to create one!
        payload.syncActions ||= []
        if (isDeleted(r)) {
          payload.syncActions.push({
            type: 'delete',
            table,
            record: r
          })
        } else {
          // does the server record exist?
          const serverRecord = data.find((s) => s.id === r.id)
          if (serverRecord) {
            // Yes - we need to update it
            payload.syncActions.push({
              type: 'update',
              table,
              record: r
            })
          } else {
            // No - we need to create it
            payload.syncActions.push({
              type: 'add',
              table,
              record: r
            } as Add<typeof table>)
          }
        }
      })
    }

    this._dispatch(onSyncDownComplete(payload))
  }

  public async onRealtimeUpdate<T extends SyncableTable>(table: T, payload: RealtimePostgresChangesPayload<Data<T>>) {
    if (payload.eventType === 'DELETE') {
      throw new Error(`Unexpected delete event for table ${table}: ${payload}`)
    }

    this.emit('info', `onRealtimeUpdate: ${table} ${payload.eventType} ${payload.new.id} ${payload.new.updated_at}`)

    const state = this._store.getState()

    const record = payload.new
    // find the record locally
    const localRecord = selectRecords(state, table).find((r) => r.id === record.id)
    if (localRecord) {
      // if the local record is equivalent or newer, ignore the update
      if (localRecord.updated_at >= record.updated_at) {
        return
      }
    } else {
      // No local record - check if deleted locally
      const locallyDeleted = state.sync.deletedLocally?.[table]?.find((r) => r.id === record.id)
      // if the local record is equivalent or newer, ignore the update
      if (locallyDeleted && locallyDeleted.updated_at >= record.updated_at) {
        return
      }
    }

    // fetch the full record from the server
    const result = await this._supabase.from(table)
      .select('*')
      .eq('id', record.id)

    if (isDeleted(record)) {
      this._dispatch(onSyncDownComplete({
        [table]: {
          deleted: result.data?.map(convertServerTimestampsToISO)
        }
      }))
    } else {
      this._dispatch(onSyncDownComplete({
        [table]: {
          updated: result.data?.map(convertServerTimestampsToISO)
        }
      }))
    }
  }
}

export class SyncError extends Error {
  public readonly result: SyncResult | undefined

  constructor(message: string, result: SyncResult | undefined) {
    super(message)
    this.result = result
  }
}

function selectRecords(state: RootState, table: 'expenses'): Array<ExpenseModel>
function selectRecords(state: RootState, table: 'incidents'): Array<IncidentModel>
function selectRecords(state: RootState, table: 'attachments'): Array<AttachmentModel>
function selectRecords(state: RootState, table: 'submissions'): Array<SubmissionModel>
function selectRecords(state: RootState, table: 'advances'): Array<AdvanceModel>
function selectRecords(state: RootState, table: 'advance_requests'): Array<AdvanceRequestModel>
function selectRecords(state: RootState, table: 'notifications'): Array<NotificationModel>
function selectRecords(state: RootState, table: SyncableTable): Array<ExpenseModel | IncidentModel | AttachmentModel | SubmissionModel | AdvanceModel | AdvanceRequestModel | NotificationModel>

function selectRecords(state: RootState, table: SyncableTable) {
  switch(table) {
    case 'expenses':
      return state.expenses.expenses
    case 'incidents':
      return state.incidents.incidents
    case 'attachments':
      return state.attachments.attachments
    case 'submissions':
      return state.submissions.submissions
    case 'advances':
      return state.advances?.advances || []
    case 'advance_requests':
      return state.advances?.advanceRequests || []
    case 'notifications':
      return state.notifications?.notifications || []
    default:
      raiseUnknownSyncableTable(table)
  }
}
