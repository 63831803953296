import React, { useEffect, useState } from "react";
import { SplashScreen } from "../screens/splashScreen";
import { useSentry } from "../providers/sentry";

interface PeriodicSyncInitializerProps {
  onComplete: () => void
}

export function PeriodicSyncInitializer({
  children,
  onComplete
}: React.PropsWithChildren<PeriodicSyncInitializerProps>) {
  const Sentry = useSentry()
  
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    // Do this in the background so that it doesn't block the main rendering
    registerPeriodicSync()
      .catch((error) => {
        console.error('Error registering periodic sync:', error)
        Sentry.captureException(error)
      })
      
    setLoading(false)
    onComplete()
  }, [])

  if (loading) {
    return <SplashScreen />
  }

  return <>{children}</>
}

export async function registerPeriodicSync() {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready;
    
    if ('periodicSync' in registration) {
      const status = await navigator.permissions.query({
        name: 'periodic-background-sync' as PermissionName,
      });

      if (status.state === 'granted') {
        // Register periodic sync with minimum interval of 1 hour
        await (registration as any).periodicSync.register('check-notifications', {
          minInterval: 60 * 60 * 1000, // 1 hour in milliseconds
        });
        console.log('Periodic sync registered');
      }
    }
  }
} 
