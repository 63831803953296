import $ from 'jquery'
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import formatISO from 'date-fns/formatISO';
import { ExpenseTable } from "../../components/expenseTable";
import { NewExpenseFormButton } from "../../components/newExpenseFormButton";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit";
import { IncidentModel, deleteIncident, updateIncident } from "../../reduxToolkit/incidentsSlice";
import { EditExpenseOnUpdatedFn } from "../../components/forms/editExpenseRowForm";
import { SubmissionHistory } from "../../components/submissionHistory";
import { RequireIncidentProvided, requireIncident } from "../../wrappers/requireIncident";
import { EditableDate } from "../../components/editable/editableDate";
import { IncidentSubmitButton } from "../../components/incidentSubmitButton";
import { ExpenseModel, deleteExpense, isCompleteExpense } from "../../reduxToolkit/expensesSlice";
import { present } from "../../../lib/util/present";
import { Tooltip } from "../../components/tooltip";
import { EditableText } from "../../components/editable/editableText";
import { IncidentSelect } from '../../components/formComponents/incidentSelect';
import { chooseSubmissionType } from '../../../lib/rules/chooseSubmissionType';
import { formatDateInTimeZone } from '../../../lib/formatDateInTimeZone';
import { useCustomization } from '../../hooks/useCustomizations';
import { addWeeks, endOfYear, startOfDay } from 'date-fns';
import { useConfirm } from '../../providers/confirmDialog';
import { selectToDosForIncident } from '../../reduxToolkit/selectors/todos';
import { byCreatedAtDescending, byDueDateAscendingNullsFirst } from '../../../lib/util/sort';
import { VirtualToDo } from '../../../lib/todos/types';
import { useTodoCompletion } from '../../hooks/todos/useTodoCompletion';
import { formatCurrency } from '../../../lib/formatCurrency';
import { AdvanceModel, AdvanceRequestModel, isPendingAdvanceRequest } from '../../reduxToolkit/advancesSlice';
import { useFeature } from '../../providers/featureFlags';
import { isSubmitted } from '../../reduxToolkit/submissionsSlice';

import './show.scss'

interface IncidentShowProps extends RequireIncidentProvided {
}

function IncidentsShow({ incident }: IncidentShowProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [confirm] = useConfirm();
  
  const nextTodo = useAppSelector((s) => selectToDosForIncident(incident.id)(s).sort(byDueDateAscendingNullsFirst)[0])
  
  const incidentHasAdvances = useAppSelector((s) => 
    (s.advances?.advances && s.advances.advances.filter((a) => a.incident_id === incident.id).length > 0) ||
    (s.advances?.advanceRequests && s.advances.advanceRequests.filter((ar) => ar.incident_id === incident.id).length > 0)
  )

  const [highlightExpenseId, setHighlightExpenseId] = useState<string | null>(location.state?.expense_id || null)
  const [editingIncidentTitle, setEditingIncidentTitle] = useState<true | undefined>(undefined)
  const [editingDate, setEditingDate] = useState<true | undefined>(undefined)

  const update = useCallback((value: Partial<IncidentModel>) => {
    dispatch(updateIncident({
      id: incident.id,
      ...value,
      updated_at: formatISO(new Date())
    }))
  }, [dispatch, incident.id])

  const onExpenseUpdated = useCallback<EditExpenseOnUpdatedFn>((expense) => {
    setHighlightExpenseId(expense.id)
    if (expense.incident_id && expense.incident_id !== incident.id) {
      navigate(`/incidents/${expense.incident_id}`, { state: { expense_id: expense.id } })
    } else if (!expense.incident_id) {
      navigate('/expenses', { state: { expense_id: expense.id } })
    }
  }, [navigate, incident.id])

  const [showAllExpenses, setShowAllExpenses] = useState(false)
  const pendingExpenses = incident.expenses.filter((e) => !present(e.submitted_at))

  let headerText = <h4>Your Unsubmitted Expenses</h4>
  if (showAllExpenses) {
    headerText = <h4>All Your Expenses</h4>
  } else if (incident.expenses.length === 0) {
    headerText = <i>Add your first expense</i>
  } else if (pendingExpenses.length === 0) {
    headerText = <i>All Expenses Submitted</i>
  }

  let expensestoShow: ExpenseModel[]
  if (showAllExpenses) {
    expensestoShow = incident.expenses
  } else {
    expensestoShow = pendingExpenses
  }

  const isEditing = editingIncidentTitle || editingDate
  // We can manually set dates for maternity or add-on incidents.  Otherwise the start date is the date of 1st expense.
  const canEditDate = incident.is_maternity || incident.is_addon_incident

  const isDemoIncident = incident.description === 'Broken Leg (demo incident)'
  const isDisabled = isDemoIncident // In the future, we may have more reasons to disable editing the incident.
  const disabledTooltipText = isDemoIncident ?
    'This is a demo incident, it will be deleted in an hour.' :
    null
    
  return <div className="incidents-show">
    <div className="row incidents-show__header-row">
      <div className="col-12 incidents-show__header">
        <h1>
          <Tooltip tooltip={disabledTooltipText}>
          <EditableText
            editing={editingIncidentTitle}
            value={incident.description}
            disabled={isDisabled}
            onChange={(description) => {
              update({description})
              setEditingIncidentTitle(undefined)
            }} />
          </Tooltip>
        </h1>

        <div className="incidents-show__header__actions">
          <div>
            <Tooltip tooltip={disabledTooltipText}>
              <button className={`btn btn-sm btn-outline-info me-2 ${isDisabled ? 'disabled' : ''}`}
                  onClick={() => {
                    if (isEditing) {
                      setEditingIncidentTitle(undefined)
                      setEditingDate(undefined)
                    } else if(!isDisabled) {
                      setEditingIncidentTitle(true)
                      if (canEditDate) {
                        setEditingDate(true)
                      }
                    }
                  }}>
                {isEditing ? 'Done' : 'Edit'}
              </button>
            </Tooltip>
            <IncidentDeleteButton incident={incident} />
          </div>
          
          <div>
            <AdvanceRequestButton incident={incident} />
          </div>
          
          <div className="form-check form-switch mt-2">
            <input className="form-check-input" type="checkbox" id="is_maternity"
              checked={!!incident.is_maternity}
              onChange={(e) => {
                if (!e.target.checked && incident.is_maternity) {
                  // confirm before unchecking
                  confirm({
                    title: 'Is this really not a maternity incident?',
                    message: 'If you uncheck this box, the due date will be cleared and the start date will be set to the earliest expense date.',
                  }).then((result) => {
                    if (result) {
                      update({is_maternity: false})
                    }
                  })
                } else {
                  update({is_maternity: e.target.checked})
                }
              }}
              />
            <label className="form-check-label" htmlFor="is_maternity">
                Maternity Incident
            </label>
          </div>
        </div>
        <div className="incidents-show__header__settings">
          
        </div>
      </div>

      <div className='col-12 col-lg-6 incidents-show__info'>
        <p className=''>
          <strong>Patient:</strong> <br />
          {incident.patient_name}
        </p>
        <p className=''>
          {incident.is_maternity ?
            <>
              <strong>Due Date:</strong> <br />
              <EditableDate
                editing={editingDate}
                disabled={isDisabled || !canEditDate}
                value={incident.maternity_due_date}
                placeholder='mm/dd/yyyy'
                onChange={(maternity_due_date) => {
                  // start date is always 40 weeks before due date
                  const start_date = addWeeks(new Date(maternity_due_date), -40).toISOString()
                  
                  update({
                    maternity_due_date,
                    start_date
                  })
                  setEditingDate(undefined)
                }}
                />
            </> : <>
              <strong>Start Date:</strong> <br />
              <EditableDate
                editing={editingDate}
                disabled={isDisabled || !canEditDate}
                value={incident.start_date}
                placeholder='mm/dd/yyyy'
                onChange={(start_date) => {
                  update({
                    start_date
                  })
                  setEditingDate(undefined)
                }}
                />
            </>}
        </p>

        <div>
          <label className='form-label me-auto'><strong>Change Incident</strong></label>
          <IncidentSelect
            id={`incident-show/${incident.id || ''}`}
            className="form-select"
            value={incident.id}
            blankOption="search all expenses"
            denyNewIncidents
            onSelect={(value) => {
              if (!incident.id && !value) return
              if (incident.id && !value) return navigate(`/expenses`)

              if (value && 'id' in value && value.id !== incident.id) {
                navigate(`/incidents/${value.id}`)
              }
            }}
            />
        </div>
      </div>

      <div className='col-12 col-lg-6 incidents-show__badges'>
        <IncidentTypeBadge incident={incident} />
      </div>
    </div>
    <div className="row">
      <div className="col-12 incidents-show__documents">
        <IncidentSubmitButton key={`incident/${incident.id}/submit-btn`} incident={incident} />
      </div>

      <hr className='hr-thick' />

      <div className="col-12 incidents-show__expenses">
        <div className="incidents-show__expenses-header">
          <div>
            {headerText}

            <button className="btn btn-link" onClick={() => setShowAllExpenses(!showAllExpenses)}>
              {showAllExpenses ?
                'Show Unsubmitted Expenses Only' :
                'Show All Expenses'}
            </button>
          </div>

          <div className="incidents-show__expenses-add">
            <NewExpenseFormButton
              incidentId={incident.id}
              allowNewIncident={false}
              disabled={isDemoIncident}
              tooltip={disabledTooltipText}
              prefilledData={{
                patient_name: incident.patient_name,
                patient_dob: incident.patient_dob,
                incident_id: incident.id
              }}
              disabledFields={[
                'patient_name',
                'patient_dob'
              ]}
              onInserted={!isDemoIncident ? onExpenseUpdated : undefined} />
            <Link to={`/incidents/${incident.id}/bulkAddExpense`}
                className="btn btn-link incidents-show__expenses-add__bulk">
              add multiple expenses
            </Link>
          </div>
        </div>

        <ExpenseTable expenses={expensestoShow}
          displayOptions={{ hideIncident: true, highlightExpenseId }}
          onExpenseUpdated={!isDemoIncident ? onExpenseUpdated : undefined} />
          
        {nextTodo && <>
          <hr />
          <i>Your Next Step:</i>
          <NextTodo todo={nextTodo} />
        </>}
      </div>
      
      {incidentHasAdvances && <div className="col-12 incidents-show__advances mt-4">
        <h4>Advances</h4>
        <AdvanceRequestHistory incident={incident} />
      </div>}

      {(incident.is_addon_incident || incident.submissions.filter(isSubmitted).length > 0) && <>
        <div className="col-12 incidents-show__submissions mt-4">
          <h4>Previous Submissions</h4>
          <SubmissionHistory submissions={incident.submissions} />
          {incident.is_addon_incident &&
            <div className='submission-history__row col-12 card'>
              <div className="card-body">
                <div className={`submission-history__row-header`}>
                  <div className="card-title">
                    <Tooltip tooltip='You checked that you had previously submitted some expenses to CHM for this incident, but did not track them here.'>
                      <i>Previously Submitted to CHM before {formatDateInTimeZone(incident.created_at, { format: 'MMM dd' })}</i>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>}
        </div>
        </>}
    </div>
  </div>
}

export default requireIncident(IncidentsShow)

function IncidentDeleteButton({ incident }: RequireIncidentProvided) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  if (present(incident.submitted_at)) {
    // Can't delete an incident that was submitted in the past
    return null
  }

  const completeExpenses = incident.expenses.filter(isCompleteExpense)

  if (completeExpenses.length > 0) {
    return <Tooltip
        tooltip="Delete your pending expenses or move them to another incident first">
      <button className="btn btn-sm btn-outline-secondary disabled">Delete</button>
    </Tooltip>
  }

  // Either 0 expenses or only incomplete expenses.
  return <button className="btn btn-sm btn-outline-danger"
      onClick={() => {
        if (incident.expenses.length > 0 &&
            !window.confirm(`Delete this incident and its ${incident.expenses.length} expenses?`)) {
          return
        }

        const now = new Date().toISOString()
        incident.expenses.forEach((expense) => {
          dispatch(deleteExpense({
            id: expense.id,
            updated_at: now,
            deleted_at: now
          }))
        })
        dispatch(deleteIncident({
          id: incident.id,
          updated_at: now,
          deleted_at: now
        }))

        navigate(-1)
      }}>
    Delete
  </button>
}

function AdvanceRequestButton({ incident }: RequireIncidentProvided) {
  const advanceTrackingEnabled = useFeature('advance_tracking')
  const hasChmSubmission = incident.submissions.some((s) => isSubmitted(s) && s.submission_type === 'CHM')
  const advanceRequests = useAppSelector((s) => s.advances.advanceRequests?.filter((ar) => ar.incident_id === incident.id) || [])
  const advances = useAppSelector((s) => s.advances.advances?.filter((a) => a.incident_id === incident.id) || [])
  
  if (!advanceTrackingEnabled) {
    return null
  }
  
  if (!hasChmSubmission) {
    return <Tooltip tooltip="You must first submit this incident to CHM before requesting an advance.">
        <button className='btn btn-link btn-sm disabled'>Request an Advance</button>
      </Tooltip>
  }
  
  if (advanceRequests.length === 0 && advances.length === 0) {
    return <Link to={`/incidents/${incident.id}/advance-request/new`} className='btn btn-link btn-sm'>Request an Advance</Link>
  }
  
  const hasOpenAdvanceRequest = advanceRequests.some(isPendingAdvanceRequest)
  if (hasOpenAdvanceRequest) {
    return <Tooltip tooltip="You can only have one open advance request at a time.">
        <button className='btn btn-link btn-sm disabled'>Request another Advance</button>
      </Tooltip>
  }
  
  const expenseIDsCoveredByAdvance = [
    ...advances.map((a) => a.expense_ids).flat(),
    ...advanceRequests.map((ar) => ar.expense_ids).flat()
  ].filter(present)
  const expenseIDsSubmittedToChm = incident.submissions
    .filter((s) => s.submission_type === 'CHM' || s.submission_type === 'CHM-addon')
    .map((s) => s.expense_ids).flat().filter(present)
  
  const hasChmSubmittedExpensesNotCoveredByAdvance = expenseIDsSubmittedToChm.some((id) => !expenseIDsCoveredByAdvance.includes(id))
  const hasUnsubmittedExpenses = incident.expenses.some((e) => !expenseIDsCoveredByAdvance.includes(e.id))
  if (hasChmSubmittedExpensesNotCoveredByAdvance) {
    return <Link to={`/incidents/${incident.id}/advance-request/new`} className='btn btn-link btn-sm'>Request another Advance</Link>
  } else if (hasUnsubmittedExpenses) {
    return <Tooltip tooltip="Please submit your remaining expenses to CHM before requesting another advance.">
        <button className='btn btn-link btn-sm disabled'>Request another Advance</button>
      </Tooltip>
  } else {
    return <Tooltip tooltip="You already have an advance for your submitted expenses.  You must submit any new expenses to CHM before requesting another advance.">
        <button className='btn btn-link btn-sm disabled'>Request another Advance</button>
      </Tooltip>
  }
}

function AdvanceRequestHistory({ incident }: RequireIncidentProvided) {
  const advances = useAppSelector(s => s.advances.advances?.filter((a) => a.incident_id === incident.id) || [])
  const advanceRequests = useAppSelector((s) => s.advances.advanceRequests?.filter((ar) => ar.incident_id === incident.id) || [])
  const navigate = useNavigate()
  
  type ListItem = (
    {
      // An advance request where funds haven't been disbursed yet
      created_at: string,
      advanceRequest: AdvanceRequestModel,
      advance: null
    } | {
      // An advance request where funds have been disbursed
      created_at: string,
      advance: AdvanceModel,
      advanceRequest: AdvanceRequestModel
    } | {
      // An advance that was given without a request
      created_at: string,
      advance: AdvanceModel,
      advanceRequest: null
    }
  )
  
  const listItems: Array<ListItem> = [
    // Any advances that were requested
    ...advanceRequests.map((ar) => ({
      created_at: ar.created_at,
      advanceRequest: ar,
      advance: advances.find((a) => a.advance_request_id === ar.id) || null
    })),
    // Any advances that were given but not requested
    ...advances.filter((a) => !a.advance_request_id).map((a) => ({
      created_at: a.created_at,
      advance: a,
      advanceRequest: null
    }))
  ]
  
  const sortedAdvances = listItems.sort(byCreatedAtDescending)
  return <div className='advance-request-history'>
    {sortedAdvances.map((ar) => {
      if (!ar.advance) {
        return <div key={ar.advanceRequest.id} className='row'>
          <div className={`col-12 card advance-request-history__row`}>
            <div className="card-body">
              <div className={`advance-request-history__row-header`}>
                <Tooltip htmlElement='div' className="card-title d-flex"
                    tooltip="Your advance request is still in progress.  Please contact your plan administrator if you have any questions.">
                  {formatDateInTimeZone(ar.created_at, { format: 'MMM dd' })}
                  &nbsp;-&nbsp;
                  {formatCurrency(ar.advanceRequest.approved_amount ?? ar.advanceRequest.amount)}
                  
                  {ar.advanceRequest.approved_at ?
                    <span className='badge bg-info ms-auto'>Approved (not released)</span> :
                    ar.advanceRequest.rejected_at ?
                      <span className='badge bg-danger ms-auto'>Rejected</span> :
                      <span className='badge bg-info ms-auto'>Pending</span>}
                </Tooltip>
                
                {ar.advanceRequest.approved_amount && (ar.advanceRequest.approved_amount != ar.advanceRequest.amount) &&
                  <Tooltip tooltip={`Your plan administrator has chosen to advance a different amount, not the original request amount of ${formatCurrency(ar.advanceRequest.amount)}.`}>
                    <small className='text-muted'>
                      (amount changed)
                    </small>
                  </Tooltip>}
              </div>
            </div>
          </div>
        </div>
      }
      
      // Funds have been released, show the advance
      return (
        <div key={ar.advance.id} className='row'>
          <div
            className={`col-12 card advance-request-history__row ${ar.advance.repaid_at ? 'advance-request-history__row--repaid' : 'advance-request-history__row--pending'}`}
            onClick={!ar.advance.repaid_at ? () => navigate(`/incidents/${incident.id}/advances/${ar.advance.id}`) : undefined}
            style={{ cursor: !ar.advance.repaid_at ? 'pointer' : 'default' }}
          >
            <div className="card-body">
              <div className={`advance-request-history__row-header`}>
                <div className="card-title d-flex">
                  {formatDateInTimeZone(ar.created_at, { format: 'MMM dd' })}
                  &nbsp;-&nbsp;
                  {formatCurrency(ar.advance.amount)}
                  
                  {ar.advance.repaid_at ?
                    <span className='badge bg-success ms-auto'>Repaid</span> :
                    ar.advance.indicated_repaid_at ?
                      <span className='badge bg-info ms-auto'>Awaiting Receipt of Repayment</span> :
                      <span className='badge bg-info ms-auto'>Funds Sent</span>}
                </div>
                
                {ar.advanceRequest?.approved_amount && (ar.advanceRequest.approved_amount != ar.advanceRequest.amount) &&
                  <Tooltip tooltip={`Your plan administrator has chosen to advance a different amount, not the original request amount of ${formatCurrency(ar.advanceRequest.amount)}.`}>
                    <small className='text-muted'>
                      (amount changed)
                    </small>
                  </Tooltip>}
              </div>
            </div>
          </div>
        </div>
      )
    })}
    </div>
}

function IncidentTypeBadge({ incident }: RequireIncidentProvided) {
  const hra = useCustomization('hra')
  const submissionType = chooseSubmissionType(
    {
      incident,
      incidentExpenses: incident.expenses,
      incidentSubmissions: incident.submissions
    },
    {hra}
  )

  switch(submissionType) {
    case 'CHM':
      return <>
        <div className="d-none d-md-block">
          <p>This incident qualifies for reimbursement from CHM.</p>
        </div>
        <Tooltip tooltip="This incident qualifies for reimbursement from CHM.">
          <div className='badge bg-info d-md-none'>
            CHM
          </div>
        </Tooltip>
      </>
    case 'CHM-addon':
      return <>
      <div className="d-none d-md-block">
        <p>This incident was previously submitted to CHM, so all new expenses will be add-on expenses.</p>
      </div>
      <Tooltip tooltip={"This incident was previously submitted to CHM, so all new expenses will be add-on expenses."}>
        <div className='badge bg-secondary d-md-none'>
          CHM Add-On
        </div>
      </Tooltip>
      </>
    case 'HRA':
      return <>
      <div className="d-none d-md-block">
        <p>This incident does not qualify for reimbursement from CHM.  It may be eligible for reimbursement through your HRA.</p>
      </div>
      <Tooltip tooltip="This incident does not qualify for reimbursement from CHM.  It may be eligible for reimbursement through your HRA.">
        <div className='badge bg-secondary d-md-none'>
          Not CHM Eligible
        </div>
      </Tooltip>
    </>
    case null:
      if (incident.expenses.length === 0) {
        return <>
          <div className="d-none d-md-block">
            <p>
              You have not added any expenses to this incident yet.
            </p>
          </div>
          <Tooltip tooltip="You have not added any expenses to this incident yet.">
            <div className='badge bg-secondary d-md-none'>
              No Expenses
            </div>
          </Tooltip>
        </>
      }
    
      if (incident.expenses.some((e) => !isCompleteExpense(e))) {
        return <>
          <div className="d-none d-md-block">
            <p>
              Some of your expenses are missing data.  Please complete them before submitting.
            </p>
          </div>
          <Tooltip tooltip="Some of your expenses are missing data.  Please complete them before submitting.">
            <div className='badge bg-secondary d-md-none'>
              Missing Data
            </div>
          </Tooltip>
        </>
      }

      let tooltip = 'This incident does not qualify for reimbursement.'
      if (hra?.providesHraCard) {
        tooltip += '  If all your expenses were paid with the HRA card, there is no need to submit for reimbursement.'
      }
      return <>
      <div className="d-none d-md-block">
        <p>
          This incident does not qualify for reimbursement.<br/>
          {hra?.providesHraCard && 'If all your expenses were paid with the HRA card, there is no need to submit for reimbursement.'}
        </p>
      </div>
      <Tooltip tooltip={tooltip}>
        <div className='badge bg-secondary d-md-none'>
          No Reimbursement
        </div>
      </Tooltip>
    </>
    default:
      return null
  }
}

function NextTodo({ todo: t }: { todo: VirtualToDo }) {
  const [onClick, _, modal] = useTodoCompletion()
  
  const today = useMemo(() => startOfDay(new Date(Date.now())).toISOString(), [])
  const eoy = useMemo(() => endOfYear(new Date(Date.now())).toISOString(), [])

  const isPast = t.dueDate && t.dueDate < today
  const showYear = t.dueDate && t.dueDate > eoy
          
  return <div className='incidents-show-next-todo' onClick={() => onClick(t)}>
    <button className='incidents-show-next-todo__title btn btn-link'>
      {t.title}
    </button>

    {t.dueDate && <>
        <br className='d-md-none'/>&nbsp;{isPast ?
          <span className='text-danger'><Tooltip tooltip={`This was due on ${formatDateInTimeZone(t.dueDate, { format: 'MMMM d, yyyy' })}`}>past due!</Tooltip></span> :
          <span>&nbsp;before {formatDateInTimeZone(t.dueDate, { format: showYear ? 'MMMM d, yyyy' : 'MMMM d' })}</span>}
      </>}
      
    {modal}
  </div>
}
