import { selectPendingExpenses } from "../../app/hooks/usePendingExpenses"
import { getOrBuildPendingSubmissionModel } from "../../app/hooks/usePendingSubmission"
import { isCompleteExpense } from "../../app/reduxToolkit/expensesSlice"
import { IncidentModel } from "../../app/reduxToolkit/incidentsSlice"
import { RootState } from "../../app/reduxToolkit/store"
import { TodoDependency, VirtualToDo } from "./types"
import { present } from "../util/present"
import { calculateDueDate } from "../rules/chooseSubmissionType"
import { formatCurrency } from "../formatCurrency"
import { Customizations, selectCustomizations } from "../../app/reduxToolkit/selectors/customizations"
import { addDays, addWeeks, formatISO } from "date-fns"
import { markHasCalledCHMForPregnancy } from "../../app/reduxToolkit/actions/markHasCalledCHMForPregnancy"
import { parseDateInTimeZone } from "../formatDateInTimeZone"
import { Decimal } from "decimal.js"

export function * createToDosForIncident(s: RootState, incident: IncidentModel): Generator<VirtualToDo> {
  const customizations = selectCustomizations(s)
  
  let checks: Array<(s: RootState, incident: IncidentModel, customizations: Customizations) => VirtualToDo | void> = [
    checkSubmitTodo,
    checkMaternityCallChmTodo,
    checkMaternityPrepaymentAgreementTodo
  ]
  
  for (const check of checks) {
    const todo = check(s, incident, customizations)
    if (todo) {
      yield todo
    }
  }
}

function checkSubmitTodo(s: RootState, incident: IncidentModel, customizations: Customizations): VirtualToDo | void {
  
  const key = `incident/${incident.id}/submit`
  const incidentExpenses = s.expenses.expenses.filter((e) => e.incident_id === incident.id)
  const incidentSubmissions = s.submissions.submissions.filter((s) => s.incident_id === incident.id)

  // If there are any expenses, this incident needs a TODO.
  if (incidentExpenses.length > 0) {
    const pendingSubmission = getOrBuildPendingSubmissionModel(
      {
        incident,
        incidentSubmissions,
        incidentExpenses
      },
      customizations
    )
    if (!pendingSubmission) { return }

    const pendingExpenses = selectPendingExpenses(pendingSubmission)(s)
    const complete = pendingExpenses.length === 0
    if (!complete) {
      const incompleteExpenses = pendingExpenses.filter((e) => !isCompleteExpense(e))
      const total = pendingExpenses.filter(isCompleteExpense).reduce((sum, expense) => {
        if (!expense?.paidAmount) { return sum }
        const amount = new Decimal(expense.paidAmount)
        return sum.plus(amount)
      }, new Decimal(0))

      if (total.gt(0)) {
        const earliestExpenseDate = pendingExpenses.map((e) => e.date).filter(present).sort()[0]
        const dueDate = calculateDueDate(earliestExpenseDate, {
          submissionType: pendingSubmission.submission_type
        })

        return {
          key,
          title: `Submit '${incident.description}' to get ${formatCurrency(total)} reimbursement`,
          dueDate,
          dependsOn: [
            ...incompleteExpenses.map<TodoDependency>((e) => (
              {
                key: `expense/${e.id}/fill`,
                record_id: e.id,
                record_type: 'expense'
              }
            )),
            ...pendingExpenses.map<TodoDependency | null>((e) => {
              // do we need "receipt" or "itemized bill"?
              let todoType: string | null = null
              // If the submission is going to CHM or CHM-Addon, we need an itemized bill.
              if (pendingSubmission.submission_type === 'CHM' || pendingSubmission.submission_type === 'CHM-addon') {
                todoType = 'itemized-bill'
              }
              // If the submission is going to the HRA, AND this expense was a personal expense, we need a receipt.
              else if (pendingSubmission.submission_type === 'HRA' && !e.paid_with_hra) {
                todoType = 'receipt'
              }
              // Otherwise we don't need a TODO here.
              else { return null }
              
              return {
                key: `expense/${e.id}/${todoType}`,
                record_id: e.id,
                record_type: 'expense'
              }
            }).filter(present)
          ],
          record_id: incident.id,
          record_type: 'incident',
          todo_type: 'submit',
          action: {
            type: 'navigate',
            route: `/incidents/${incident.id}`
          }
        }
      }
    }
  }
}

function checkMaternityCallChmTodo(s: RootState, incident: IncidentModel, customizations: Customizations): VirtualToDo | void {
  if (!incident.is_maternity) { return }
  if (!incident.maternity_due_date) { return }
  if (incident.maternity_chm_call_completed_at) { return }
  
  let dueDate = addWeeks(parseDateInTimeZone(incident.maternity_due_date), -40 + 16);
  dueDate = addDays(dueDate, -1);
  return {
    key: `incident/${incident.id}/maternity-call-chm`,
    title: `Call CHM for pregnancy support`,
    record_id: incident.id,
    record_type: 'incident',
    todo_type: 'maternity-call-chm',
    dueDate: formatISO(dueDate, { representation: 'date' }),
    action: {
      type: 'dispatch',
      action: markHasCalledCHMForPregnancy({incident_id: incident.id}),
            
      confirm: {
        title: `Did you call CHM to let them know you're pregnant?`,
        body: 'When you do this prior to 16 weeks pregnant, CHM will take $500 off your Personal Responsibility.'
      },
    }
  }
}

/**
 * Instructs the user to call the hospital and get a global bill for the pregnancy.
 * 
 * This should be done after initially calling CHM for maternity support.
 */
function checkMaternityPrepaymentAgreementTodo(s: RootState, incident: IncidentModel, customizations: Customizations): VirtualToDo | void {
  if (!incident.is_maternity) { return }
  if (!incident.maternity_due_date) { return }
  if (!incident.maternity_chm_call_completed_at) { return }
  
  const incidentHasPrepaymentAgreement = s.expenses.expenses.some((e) => e.incident_id === incident.id && e.is_prepayment_agreement)
  if (incidentHasPrepaymentAgreement) { return }
  
  const dueDate = parseDateInTimeZone(incident.maternity_due_date);
  return {
    key: `incident/${incident.id}/maternity-prepayment-agreement`,
    title: `Get a prepayment agreement from the hospital`,
    record_id: incident.id,
    record_type: 'incident',
    todo_type: 'maternity-prepayment-agreement',
    dueDate: formatISO(dueDate, { representation: 'date' }),
    action: {
      type: 'navigate',
      route: `/incidents/${incident.id}`
    }
  }
}
