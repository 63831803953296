import { Decimal } from 'decimal.js';

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function formatCurrency(amount: Decimal | number | string) {
  let decimal: Decimal;
  
  if (amount instanceof Decimal) {
    decimal = amount
  } else if (typeof amount === 'string') {
    // Remove $ if present
    const cleanAmount = amount.startsWith('$') ? amount.substring(1) : amount;
    decimal = new Decimal(cleanAmount);
  } else {
    decimal = new Decimal(amount);
  }
  
  // Round to 2 decimal places
  const decimalString = decimal.toFixed(2);
  
  return formatter.format(Number(decimalString)).replace(/\.00$/, '');
}
