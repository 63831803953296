import { useParams } from "react-router"
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxToolkit"
import { NotFound } from "../../../screens/notFound"
import { formatCurrency } from "../../../../lib/formatCurrency"
import { formatDateInTimeZone, parseDateInTimeZone } from "../../../../lib/formatDateInTimeZone"
import { AdvanceModel, indicateAdvanceRepaid } from "../../../reduxToolkit/advancesSlice"
import { IncidentModel } from "../../../reduxToolkit/incidentsSlice"
import { bySubmittedAtDesc } from "../../../../lib/util/sort"
import { isSubmitted } from "../../../reduxToolkit/submissionsSlice"
import { addDays, addWeeks } from "date-fns"
import { selectChmSubmissionsCoveredByAdvance } from "../../../reduxToolkit/selectors/selectChmSubmissionsCoveredByAdvance"
import { useConfirm } from "../../../providers/confirmDialog"
import { useUser } from "../../../hooks/useUser"
import { Tooltip } from "../../../components/tooltip"

export function AdvanceShow() {
  const {incidentId, advanceId} = useParams()
  const incident = useAppSelector((state) => state.incidents.incidents.find(i => i.id === incidentId))
  const advance = useAppSelector((state) => state.advances?.advances?.find(a => a.id === advanceId))
  const advanceRequest = useAppSelector((state) => state.advances?.advanceRequests?.find(ar => ar.id === advance?.advance_request_id))
  
  const includedExpenses = useAppSelector((s) => s.expenses.expenses?.filter(e => advance?.expense_ids?.includes(e.id)) || [])
  
  if (!incident || !advance) {
    return <NotFound />
  }
  
  return <div className="advance-show">
    <div className="row advance-show__header-row">
      <div className="col-12">
        <h1>Advance</h1>
      </div>
      
      <div className="col-12">
        <p>
          <strong>Incident:</strong> {incident.description}<br/>
          {advanceRequest && advanceRequest.amount != advance.amount ? <>
            <Tooltip tooltip={`Your plan administrator has chosen to advance a different amount, not the original request amount of ${formatCurrency(advanceRequest.amount)}.  Contact your plan administrator if you have questions.`}>
              <strong>Requested Amount:</strong> {formatCurrency(advanceRequest.amount)}<br/>
              <strong>Advanced Amount:</strong> {formatCurrency(advance.amount)}<br/>
                <small className='text-muted'>
                  (amount changed)
                </small>
              </Tooltip>
            </> : <>
              <strong>Advanced Amount:</strong> {formatCurrency(advance.amount)}<br/>
            </>}
        </p>
      </div>
    </div>
    
    <div className="row mt-2">
      <div className="col-12">
        <h4>Included Expenses</h4>
        <ul className="checklist">
          {includedExpenses.map(expense => {
            const {date, paidAmount, provider} = expense
            
            return <li key={expense.id} className="checklist__item">
              <div className="checklist__item__description">
                {date ? formatDateInTimeZone(date, { format: 'MM/dd/yyyy' }) : 'Unknown Date'}
                &nbsp;-&nbsp;
                {paidAmount ? formatCurrency(paidAmount) : 'Unknown Amount'}
                <br />
                <span>{provider}</span>
              </div>
            </li>
          })}
        </ul>
      </div>
    </div>
    
    <div className="row mt-2">
      <div className="col-12">
        <h4>Next Step:</h4>
        <AdvanceNextStep advance={advance} incident={incident} />
      </div>
    </div>
  </div>
}

function AdvanceNextStep({advance, incident}: {advance: AdvanceModel, incident: IncidentModel}) {
  const chmSubmissionsForAdvance = useAppSelector(selectChmSubmissionsCoveredByAdvance(advance))
  
  const latestCHMSubmissionDate = chmSubmissionsForAdvance.filter(isSubmitted).sort(bySubmittedAtDesc).at(0)?.submitted_at
  
  if (advance.repaid_at) {
    return <div>
      <p>This advance has been repaid. You've taken care of everything!  Thank you!</p>
    </div>
  }

  if (advance.indicated_repaid_at) {
    const wasMoreThanTwoWeeksAgo = addWeeks(parseDateInTimeZone(advance.indicated_repaid_at), 2) < new Date()
    if (wasMoreThanTwoWeeksAgo) {
      return <div>
        <p>
          It's been more than 2 weeks since you indicated that you repaid the advance.
          Please check with your plan administrator to confirm that they received your repayment.
        </p>
        <p>
          Once they confirm receipt of your repayment, this checklist item will be marked complete.
        </p>
      </div>
    }
    
    return <div>
      <p>
        Thanks for repaying the advance!  Your plan administrator has been notified.
      </p>
    </div>
  }
  
  if (latestCHMSubmissionDate) {
    const chmReimbursementDate = addDays(parseDateInTimeZone(latestCHMSubmissionDate), 120)
    
    return <div>
      <p>
        Your Advance funds have been released to your bank account.
      </p>
      <p>
        You can expect reimbursement from CHM in about 90-120 days, 
        hopefully no later than <b>{formatDateInTimeZone(chmReimbursementDate, { format: 'MMM dd, yyyy' })}</b>.<br/>
        Once you receive the reimbursement, you must repay the advance.
      </p>
      <IndicateRepaymentButton advance={advance} />
    </div>
  }
  
  return <div>
    <p>
      Your Advance funds have been released to your bank account.
    </p>
    <p>
      You can expect reimbursement from CHM in about 90-120 days.
      Once you receive the reimbursement, you must repay the advance.
    </p>
    <IndicateRepaymentButton advance={advance} />
  </div>
}

function IndicateRepaymentButton({advance}: {advance: AdvanceModel}) {
  const dispatch = useAppDispatch()
  const [confirm] = useConfirm()
  const currentUser = useUser()
  
  return <button className="btn btn-primary"
    onClick={() => confirm({
      message: `Did you write a check for ${formatCurrency(advance.amount)} to repay the advance?`,
      actions: [
        {
          title: 'Yes',
          isConfirm: true,
          onClick: () => {
            const now = new Date().toISOString()
            dispatch(indicateAdvanceRepaid({
              id: advance.id,
              updated_at: now,
              indicated_repaid_at: now,
            }))
            
          }
        },
        {
          title: 'No',
          isCancel: true,
        }
      ],
    })}
  >
    I have repaid this advance
  </button>
}
