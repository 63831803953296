import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { byCreatedAtDescending } from '../../lib/util/sort';
import { onSyncDownComplete, OnSyncDownCompletePayload } from './actions/onSyncDownComplete';
import { replaceOrInsert } from '../../lib/util/replaceOrInsert';
import { redactObjectExceptKeys } from '../../lib/util/redact';
import { NotificationRow, NotificationInsert, NotificationUpdate, NotificationRecordType, NotificationType } from '../../types/supabase';

export interface NotificationModel {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
  membership_id: string;
  sent_at?: string | null;
  read_at?: string | null;
  record_id?: string | null;
  record_type?: NotificationRecordType | null;
  notification_type: NotificationType;
  to_user_ids?: string[] | null;
  title: string;
  body?: string | null;
}

interface NotificationsState {
  notifications: NotificationModel[];
}

const initialState: NotificationsState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    markNotificationAsRead: (state, action: PayloadAction<{ id: string, read_at: string, updated_at: string }>) => {
      const notification = state.notifications?.find(item => item.id === action.payload.id);
      if (notification) {
        notification.read_at = action.payload.read_at;
        notification.updated_at = action.payload.updated_at;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onSyncDownComplete, (state, action: PayloadAction<OnSyncDownCompletePayload>) => {
      state.notifications ||= []

      // Update any expenses or incidents that have been updated on the server
      for (const notification of action.payload.notifications?.updated || []) {
        replaceOrInsert(state.notifications, notification)
      }

      for (const deletedNotification of action.payload.notifications?.deleted || []) {
        const i = state.notifications.findIndex((t) => t.id === deletedNotification.id)
        if (i >= 0 && state.notifications[i].updated_at <= deletedNotification.updated_at) {
          state.notifications.splice(i, 1)
        }
      }
      state.notifications.sort(byCreatedAtDescending);
    });
  }
});

export const { markNotificationAsRead } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export function isAdvanceRequestNotification(notification: NotificationModel): notification is NotificationModel & { record_type: 'advance_request' } {
  return notification.record_type === 'advance_request'
}

export function assertIsAdvanceRequestNotification(notification: NotificationModel): asserts notification is NotificationModel & { record_type: 'advance_request' } {
  if (!isAdvanceRequestNotification(notification)) {
    throw new Error(`Notification is not an advance request notification: ${notification.record_type}`)
  }
}

export function isAdvanceNotification(notification: NotificationModel): notification is NotificationModel & { record_type: 'advance' } {
  return notification.record_type === 'advance'
}

export function assertIsAdvanceNotification(notification: NotificationModel): asserts notification is NotificationModel & { record_type: 'advance' } {
  if (!isAdvanceNotification(notification)) {
    throw new Error(`Notification is not an advance notification: ${notification.record_type}`)
  }
}

export const redactNotifications = (state: NotificationsState): NotificationsState => ({
  notifications: state.notifications.map(redactNotification),
});

export function redactNotification(notification: NotificationModel): NotificationModel {
  return {
    ...redactObjectExceptKeys(notification, 'id', 'created_at', 'updated_at', 'sent_at', 'read_at', 'record_id', 
        'record_type', 'to_user_ids', 'notification_type'),
  };
}

export const isNotificationsSliceAction = (action: any): action is PayloadAction<any> =>
  action.type.startsWith('notifications/');

export const redactNotificationsSliceAction = (action: PayloadAction<any>): PayloadAction<any> => ({
  ...action,
  payload: 'REDACTED',
});

// Assert we can assign an NotificationRow to an NotificationModel
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertNotificationRowAssignable: NotificationModel = {} as NotificationRow


// Assert we can assign an NotificationModel to an NotificationInsert
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertNotificationInsertAssignable: NotificationInsert = {} as NotificationModel


// Assert we can assign an NotificationModel to an NotificationUpdate
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertNotificationUpdateAssignable: NotificationUpdate = {} as NotificationModel
