import { useSentry } from "../providers/sentry"
import { useClient, useSession } from "../providers/supabase"
import { useAsyncAction } from "./useAsyncAction"

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL

export function useMailchimpSignUpHandler(params?: { onSuccess?: () => void, onError?: (err: Error) => void }) {
  const { captureException } = useSentry()
  const client = useClient()
  const userId = useSession()?.user?.id
  
  const { onSuccess, onError } = params || {}

  return useAsyncAction(async (tags?: string[]) => {
    try {
      await fetch(`${supabaseUrl}/functions/v1/add-user-to-mailchimp-list`, {
        method: 'POST',
        body: JSON.stringify({ userId, tags })
      })
      onSuccess?.()
    } catch(err) {
      captureException(err)
      console.log('onError!', err)
      onError?.(err as Error)
      throw err
    }
  }, [client, userId, onSuccess]) 
}
