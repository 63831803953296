import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useReturnTo } from "../hooks/useReturnTo";

export function NotFound({children}: React.PropsWithChildren) {
  const [returningTo, setReturningTo] = useState(false)

  useReturnTo()

  useEffect(() => {
    const returningTo = localStorage.getItem('returningTo')
    localStorage.removeItem('returningTo')
    setReturningTo(!!returningTo)
  }, [])
  
  if (returningTo) {
    return <div className="row">
      <div className="col">
        <h1>Please Wait...</h1>
        <p>We're redirecting you to the page you were trying to access.</p>
        
        <p>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </p>
        
        <p>
          <span className="text-muted">
            If you're not redirected, please <Link to="/">click here to return to the homepage</Link>.
          </span>
        </p>
      </div>
    </div>
  }

  return <div className="row">
    <div className="col">
      <h1>404 Not Found</h1>
      {children ||
        <p>We couldn't find what you're looking for.</p>}

      <Link to="/">Home</Link>
    </div>
  </div>
}
