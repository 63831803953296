import { useEffect } from "react";
import { useAppSelector } from "../hooks/reduxToolkit";
import { usePushNotifications } from "../providers/pushNotifications";
import { selectUnreadNotifications } from "../reduxToolkit/selectors/selectUnreadNotifications";

export function AppBadgeListener() {
  const notifications = usePushNotifications();
  const unreadNotificationsCount = useAppSelector((s) => selectUnreadNotifications(s).length)
  
  useEffect(() => {
    function updateBadge() {
      if (unreadNotificationsCount > 0) {
        notifications.setBadgeCount(unreadNotificationsCount)
      } else {
        notifications.clearBadgeCount()
      }
    }
    updateBadge()
    
    // re-set the badge count when the app is brought back to the foreground
    document.addEventListener('visibilitychange', updateBadge)
    return () => {
      document.removeEventListener('visibilitychange', updateBadge)
    }
    
  }, [unreadNotificationsCount])
  
  return null;
}
