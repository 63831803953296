import { useAppDispatch, useAppSelector } from '../hooks/reduxToolkit'
import { formatDistanceToNow, subMonths } from 'date-fns'
import { parseDateInTimeZone } from '../../lib/formatDateInTimeZone'
import { byCreatedAtDescending } from '../../lib/util/sort'
import { markNotificationAsRead, NotificationModel } from '../reduxToolkit/notificationsSlice'
import { selectNotificationAssociatedRecord } from '../reduxToolkit/selectors/selectNotificationAssociatedRecord'
import { useSentry } from '../providers/sentry'
import { useNavigate } from 'react-router'
import { useEffect, useMemo } from 'react'
import { usePushNotifications } from '../providers/pushNotifications'
import { selectUnreadNotifications } from '../reduxToolkit/selectors/selectUnreadNotifications'

import './notifications.scss'

export function Notifications() {
  const unreadNotifications = useAppSelector(selectUnreadNotifications)
  const originalUnreadNotifications = useMemo(() => [...unreadNotifications], [])

  const dispatch = useAppDispatch()
  const Sentry = useSentry()
  const pushNotifications = usePushNotifications()
  
  // When we first load the notifications page, we prompt the user to grant push notification permissions if they haven't already made a decision.
  useEffect(() => {
    if (originalUnreadNotifications.length <= 0) {
      return
    }

    const permissionState = pushNotifications.getPermissionState()
    if (permissionState === 'default') {
      // Don't await this, we just want to try to prompt the user
      pushNotifications.register()
        .catch((error) => {
          console.error('Error requesting notification permission:', error)
          Sentry.captureException(error)
        })
    }
  }, [originalUnreadNotifications.length <= 0, pushNotifications])
  
  const unreadCount = unreadNotifications.length
  
  // Show unread notifications, but also show any notifications that were unread when we first loaded the page but have since been read.
  const unreadNotificationIDs = unreadNotifications.map(n => n.id)
  const sortedNotifications = [
    ...unreadNotifications,
    ...originalUnreadNotifications.filter(n => !unreadNotificationIDs.includes(n.id))
  ].sort(byCreatedAtDescending)

  if (sortedNotifications.length === 0) {
    return <div className="row notifications">
      <div className="col-12">
        <h1 className="notifications__header">Notifications</h1>
        
        <div className="notifications__empty">
          <i className="material-icons-outlined">notifications_none</i>
          <p>No unread notifications</p>
        </div>
      </div>
    </div>
  }

  return <div className="row notifications">
    <div className="col-12">
      <div className="notifications__header-container">
        <h1 className="notifications__header">Notifications</h1>
        {unreadCount > 0 && (
          <div className="dropdown notifications__menu-button">
            <button 
              className="btn btn-link" 
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons-outlined">more_vert</i>
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              {unreadCount > 0 && (
                <li>
                  <button 
                    className="dropdown-item"
                    onClick={() => {
                      const now = new Date().toISOString()
                      unreadNotifications.forEach(n => {
                        if (!n.read_at) {
                          dispatch(markNotificationAsRead({ id: n.id, read_at: now, updated_at: now }))
                        }
                      })
                    }}
                  >
                    Mark all as read
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
        
        <span className="notifications__unread-count text-muted">
          {unreadCount > 0 ?
            <i>{unreadCount} unread notifications</i> :
            <i>All notifications read</i>
          }
        </span>
      </div>
      
      <div className="notifications__list">
        {sortedNotifications.map(notification => {
          return <NotificationMessage key={notification.id} notification={notification} isUnread={unreadNotificationIDs.includes(notification.id)} />
        })}
      </div>
    </div>
  </div>
}

function NotificationMessage({notification, isUnread}: {notification: NotificationModel, isUnread: boolean}): React.ReactElement {
  const associatedRecord = useAppSelector(selectNotificationAssociatedRecord(notification))
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  
  const href = associatedRecord ? `/incidents/${associatedRecord.incident_id}` : null

  return <div 
      key={notification.id} 
      className={`notification-item ${isUnread ? 'notification-item--unread' : ''} ${href ? 'notification-item--clickable' : ''}`}
  
      onClick={(e) => {
        e.preventDefault()
        const now = new Date().toISOString()
        if (isUnread) {
          dispatch(markNotificationAsRead({ id: notification.id, read_at: now, updated_at: now }))
        }
        if (href) {
          navigate(href)
        }
      }}
    >
      <div className="notification-item__content">
        <div className="notification-item__message">
          <h6 className="notification-item__title">{notification.title}</h6>
          <p className="notification-item__body">{notification.body}</p>
        </div>
        <div className="notification-item__time">
          {formatDistanceToNow(parseDateInTimeZone(notification.created_at), { addSuffix: true })}
        </div>
      </div>
    </div>
}
