import { Action, AnyAction, AsyncThunkAction, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as Sentry from "@sentry/react";

import membershipReducer, { redactMembership } from './membershipSlice'
import dependentsReducer, { redactDependents } from './dependentsSlice'
import expensesReducer, { ExpenseModel, isExpensesSliceAction, redactExpenses, redactExpensesSliceAction } from './expensesSlice'
import incidentsReducer, { IncidentModel, isIncidentsSliceAction, redactIncidents, redactIncidentsSliceAction } from './incidentsSlice'
import attachmentsReducer, { redactAttachments, isAttachmentsSliceAction, redactAttachmentsSliceAction } from './attachmentsSlice'
import submissionsReducer, { isSubmissionsSliceAction, redactSubmissions, redactSubmissionsSliceAction, SubmissionModel } from './submissionsSlice'
import syncReducer, { isSyncSliceAction, redactSyncSlice, redactSyncSliceAction } from './syncSlice'
import advancesReducer, { redactAdvances, isAdvancesSliceAction, redactAdvancesSliceAction, AdvanceModel, AdvanceRequestModel } from './advancesSlice'
import { DeepPartialNullable } from '../../types/util';
import { incidentStartDateMiddleware } from './middleware/incidentStartDateMiddleware';
import notificationsReducer, { redactNotifications, isNotificationsSliceAction, redactNotificationsSliceAction, NotificationModel } from './notificationsSlice'

const rootReducer = combineReducers({
  membership: membershipReducer,
  dependents: dependentsReducer,
  expenses: expensesReducer,
  incidents: incidentsReducer,
  attachments: attachmentsReducer,
  submissions: submissionsReducer,
  advances: advancesReducer,
  notifications: notificationsReducer,
  sync: syncReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const persistConfig: PersistConfig<RootState> = {
  // Use "Key" to version the persisted state, throwing away old state when the version changes.
  // This throws away any un-synced updates.
  key: `root/2023-04-29`,
  storage,
  version: 1,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action: AnyAction) => {
    if (isExpensesSliceAction(action)) {
      return redactExpensesSliceAction(action)
    } else if (isAttachmentsSliceAction(action)) {
      return redactAttachmentsSliceAction(action)
    } else if (isIncidentsSliceAction(action)) {
      return redactIncidentsSliceAction(action)
    } else if (isSubmissionsSliceAction(action)) {
      return redactSubmissionsSliceAction(action)
    } else if (isSyncSliceAction(action)) {
      return redactSyncSliceAction(action)
    } else if (isAdvancesSliceAction(action)) {
      return redactAdvancesSliceAction(action)
    } else if (isNotificationsSliceAction(action)) {
      return redactNotificationsSliceAction(action)
    }

    // otherwise redact the payload
    return {
      type: action.type,
      payload: 'REDACTED'
    }
  },
  stateTransformer: (state: RootState) => {
    // Transform the state to remove sensitive information
    return redactState(state)
  },
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false // redux-persist doesn't like this
  }).concat(incidentStartDateMiddleware),
  enhancers: [sentryReduxEnhancer],
})

export const persistor = persistStore(store)

// TODO: refine the available action types here
export type AppAction = Action<any> | AsyncThunkAction<any, any, any> | ThunkAction<any, any, any, any>
export type AppDispatch = typeof store.dispatch

/**
 * Redacts the state to remove sensitive information.
 * Due to HIPAA compliance, we cannot send any patient information to analytics tools like Sentry.
 */
export function redactState(state: RootState): DeepPartialNullable<RootState> {
  // Transform the state to remove sensitive information
  // Use a whitelist model
  return {
    dependents: redactDependents(state.dependents),
    expenses: redactExpenses(state.expenses),
    incidents: redactIncidents(state.incidents),
    attachments: redactAttachments(state.attachments),
    membership: redactMembership(state.membership),
    submissions: redactSubmissions(state.submissions),
    advances: redactAdvances(state.advances),
    sync: redactSyncSlice(state.sync),
    notifications: redactNotifications(state.notifications)
  }
}
