
declare global {
  interface SymbolConstructor {
    readonly dispose: unique symbol;
  }
  
  interface Disposable {
    [Symbol.dispose]: () => void;
  }
}

if (!Symbol.dispose) {
  // We are in an environment that doesn't support Symbol.dispose, so we need to define it
  (Symbol as any).dispose = Symbol('dispose')
}

export function isDisposable(obj: any): obj is Disposable {
  return typeof obj[Symbol.dispose] === 'function';
}
