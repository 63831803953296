import { InstantSearch, InstantSearchProps } from "react-instantsearch";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

const typesenseUri = new URL(import.meta.env.VITE_TYPESENSE_HOST || "http://localhost:8108") 
const apiKey = import.meta.env.VITE_TYPESENSE_SEARCH_API_KEY || "xyz"
const indexEnv = import.meta.env.VITE_TYPESENSE_INDEX_ENVIRONMENT || "dev"

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: apiKey,
    nodes: [
      {
        url: typesenseUri.toString(),
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "title,description_text",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export function TypesenseSearch(props: Omit<InstantSearchProps, 'searchClient'>) {
  
  return <InstantSearch {...props}
      indexName={[props.indexName, indexEnv].join('_')}
      searchClient={searchClient}>
      {props.children}
  </InstantSearch>
}
